// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appearance-settings-js": () => import("./../../../src/pages/appearance-settings.js" /* webpackChunkName: "component---src-pages-appearance-settings-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-edit-reseller-js": () => import("./../../../src/pages/edit-reseller.js" /* webpackChunkName: "component---src-pages-edit-reseller-js" */),
  "component---src-pages-emails-settings-js": () => import("./../../../src/pages/emails-settings.js" /* webpackChunkName: "component---src-pages-emails-settings-js" */),
  "component---src-pages-general-settings-js": () => import("./../../../src/pages/general-settings.js" /* webpackChunkName: "component---src-pages-general-settings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-lang-js": () => import("./../../../src/pages/lang.js" /* webpackChunkName: "component---src-pages-lang-js" */),
  "component---src-pages-licenses-js": () => import("./../../../src/pages/licenses.js" /* webpackChunkName: "component---src-pages-licenses-js" */),
  "component---src-pages-permissions-js": () => import("./../../../src/pages/permissions.js" /* webpackChunkName: "component---src-pages-permissions-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-resellers-[id]-js": () => import("./../../../src/pages/resellers/[id].js" /* webpackChunkName: "component---src-pages-resellers-[id]-js" */),
  "component---src-pages-resellers-add-reseller-js": () => import("./../../../src/pages/resellers/add-reseller.js" /* webpackChunkName: "component---src-pages-resellers-add-reseller-js" */),
  "component---src-pages-resellers-js": () => import("./../../../src/pages/resellers.js" /* webpackChunkName: "component---src-pages-resellers-js" */),
  "component---src-pages-sms-settings-js": () => import("./../../../src/pages/sms-settings.js" /* webpackChunkName: "component---src-pages-sms-settings-js" */),
  "component---src-pages-user-settings-display-js": () => import("./../../../src/pages/user-settings/display.js" /* webpackChunkName: "component---src-pages-user-settings-display-js" */),
  "component---src-pages-user-settings-general-js": () => import("./../../../src/pages/user-settings/general.js" /* webpackChunkName: "component---src-pages-user-settings-general-js" */),
  "component---src-pages-user-settings-js": () => import("./../../../src/pages/user-settings.js" /* webpackChunkName: "component---src-pages-user-settings-js" */),
  "component---src-pages-user-settings-language-js": () => import("./../../../src/pages/user-settings/language.js" /* webpackChunkName: "component---src-pages-user-settings-language-js" */),
  "component---src-pages-user-settings-notifications-js": () => import("./../../../src/pages/user-settings/notifications.js" /* webpackChunkName: "component---src-pages-user-settings-notifications-js" */),
  "component---src-pages-user-settings-privacy-js": () => import("./../../../src/pages/user-settings/privacy.js" /* webpackChunkName: "component---src-pages-user-settings-privacy-js" */),
  "component---src-pages-user-settings-sessions-js": () => import("./../../../src/pages/user-settings/sessions.js" /* webpackChunkName: "component---src-pages-user-settings-sessions-js" */),
  "component---src-pages-users-[name]-js": () => import("./../../../src/pages/users/[name].js" /* webpackChunkName: "component---src-pages-users-[name]-js" */),
  "component---src-pages-users-add-core-admin-js": () => import("./../../../src/pages/users/add-core-admin.js" /* webpackChunkName: "component---src-pages-users-add-core-admin-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

