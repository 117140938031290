import { IUser } from '@myneva-portals/models/src/models/user/iuser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchParams } from 'front-components/src/components';
import { Dispatch, RootState, Thunk } from '../../store';

interface AdminsState {
  admins: IUser[];
  searchParams: SearchParams;
}

const initialState: AdminsState = {
  admins: null,
  searchParams: {
    pagination: {
      limit: 10,
      offset: 0,
      totalCount: 0,
    },
    filter: [],
    sort: {
      columnName: 'last_name',
      order: 'asc',
    },
  },
};

export const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setAdmins: (state, action: PayloadAction<IUser[]>) => {
      const { payload } = action;
      state.admins = payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      const { payload } = action;
      state.searchParams = payload;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.searchParams.pagination.totalCount = payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.searchParams.pagination.limit = payload;
    },
  },
});

export default adminsSlice.reducer;

export const adminsSelector = (state: RootState) => state.admins;

const { setAdmins, setSearchParams, setTotalCount, setLimit } =
  adminsSlice.actions;

export const updateAdmins =
  (admins: IUser[]): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setAdmins(admins));
  };

export const updateSearchParams =
  (searchParams: SearchParams): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setSearchParams(searchParams));
  };

export const updateTotalCount =
  (totalCount: number): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setTotalCount(totalCount));
  };

export const updateLimit =
  (limit: number): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setLimit(limit));
  };
