import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Thunk, Dispatch } from '../../store';

interface SessionState {
  isLogedId: boolean;
  sessionId: string;
  sessionEndDate: number;
  lastUserActivityDateInMs: number;
  userName: string;
  userType: string;
  isRedirecting: boolean;
  userUuid: string;
}

const initialState: SessionState = {
  isLogedId: false,
  sessionId: '',
  sessionEndDate: null,
  lastUserActivityDateInMs: null,
  userName: '',
  userType: '',
  isRedirecting: false,
  userUuid: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsLogedId: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.isLogedId = payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.sessionId = payload;
    },
    setSessionEndDate: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.sessionEndDate = payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.userName = payload;
    },
    setUserType: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.userType = payload;
    },
    setUserUuid: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.userUuid = payload;
    },
    setIsRedirecting: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.isRedirecting = payload;
    },
    setLastUserActivityDateInMs: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.lastUserActivityDateInMs = payload;
    },
  },
});

export default sessionSlice.reducer;

export const sessionSelector = (state: RootState) => state.session;

const {
  setSessionId,
  setSessionEndDate,
  setIsLogedId,
  setUserName,
  setUserType,
  setUserUuid,
  setIsRedirecting,
  setLastUserActivityDateInMs,
} = sessionSlice.actions;

export const updateSessionId =
  (sessionId: string): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setSessionId(sessionId));
  };

export const updateSessionEndDate =
  (sessionEndDate: number): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setSessionEndDate(sessionEndDate));
  };

export const updateIsLogedIn =
  (isLogedId: boolean): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setIsLogedId(isLogedId));
  };

export const updateUserData =
  (userName: string, userType: string, userUuid: string): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setUserName(userName));
    dispatch(setUserType(userType));
    dispatch(setUserUuid(userUuid));
  };

export const updateIsRedirecting =
  (isRedirecting: boolean): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setIsRedirecting(isRedirecting));
  };

export const updateLastUserActivityDateInMs =
  (lastUserActivityDateInMs: number): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setLastUserActivityDateInMs(lastUserActivityDateInMs));
  };
