import { MenuSection } from '@myneva-portals/models/src/models/navigation/menu-section';
import PageKey from '@myneva-portals/models/src/models/page-key';

export const initialMenuStructure: MenuSection[] = [
  {
    items: [
      {
        key: PageKey.DASHBOARD,
        name: 'dashboard',
      },
    ],
  },
  {
    header: 'services',
    items: [
      {
        key: PageKey.RESELLERS,
        name: 'company',
      },
    ],
  },
  {
    header: 'management',
    items: [
      {
        key: PageKey.USERS,
        name: 'users',
      },
      {
        key: PageKey.SETTINGS,
        name: 'settings',
        items: [
          {
            key: PageKey.GENERAL_SETTINGS,
            name: 'general-settings',
          },
          {
            key: PageKey.APPEARANCE_SETTINGS,
            name: 'appearance-settings',
          },
          {
            key: PageKey.EMAILS_SETTINGS,
            name: 'emails-settings',
          },
          {
            key: PageKey.SMS_SETTINGS,
            name: 'sms-settings',
          },
        ],
      },
    ],
  },
];
