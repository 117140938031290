import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'front-components/src/services';
import { Dispatch, RootState, Thunk } from '../../store';

interface AdminDetailsState {
  admin: IUser;
}

const initialState: AdminDetailsState = {
  admin: null,
};

export const adminDetailsSlice = createSlice({
  name: 'adminsDetails',
  initialState,
  reducers: {
    setAdminsDetails: (state, action: PayloadAction<IUser>) => {
      const { payload } = action;
      state.admin = payload;
    },
  },
});

export default adminDetailsSlice.reducer;

export const adminsDetailsSelector = (state: RootState) => state.adminsDetails;

const { setAdminsDetails } = adminDetailsSlice.actions;

export const updateAdminDetails =
  (admin: IUser): Thunk =>
  (dispatch: Dispatch) =>
    dispatch(setAdminsDetails(admin));
