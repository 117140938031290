import React from 'react';
import { ThemeWrapper, ToastErrorModal } from 'front-components/src/components';
import { uiSelector } from '../../state/reducers/ui';
import { useSelector } from 'react-redux';
import { StylesProvider, Theme, ThemeProvider } from '@material-ui/core';
import { createGenerateClassName } from './create-generate-class-name';

export interface UiWrapperProps {
  children: React.ReactNode;
}

export const UiWrapper: React.FC<UiWrapperProps> = (props: UiWrapperProps) => {
  const { themeMode, fontMode } = useSelector(uiSelector);

  const generateClassName = createGenerateClassName();

  return (
    <StylesProvider
      generateClassName={generateClassName}
      disableGeneration={false}
      injectFirst
    >
      <ThemeWrapper themeMode={themeMode} fontMode={fontMode}>
        {(currentTheme: Theme) => {
          return (
            <ThemeProvider theme={currentTheme}>
              <>
                <ToastErrorModal />
                {props.children}
              </>
            </ThemeProvider>
          );
        }}
      </ThemeWrapper>
    </StylesProvider>
  );
};
