import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ResellerDetails from '@myneva-portals/models/src/models/reseller/reseller-details';
import { Dispatch, RootState, Thunk } from '../../store';

interface ResellerDetailsState {
  resellerDetails: ResellerDetails;
}

const initialState: ResellerDetailsState = {
  resellerDetails: null,
};

export const resellerDetailsSlice = createSlice({
  name: 'resellerDetails',
  initialState,
  reducers: {
    setResellerDetails: (state, action: PayloadAction<ResellerDetails>) => {
      const { payload } = action;
      state.resellerDetails = payload;
    },
  },
});

export default resellerDetailsSlice.reducer;

export const resellerDetailsSelector = (state: RootState) =>
  state.resellerDetails;

const { setResellerDetails } = resellerDetailsSlice.actions;

export const updateResellerDetails =
  (reseller: ResellerDetails): Thunk =>
  (dispatch: Dispatch) =>
    dispatch(setResellerDetails(reseller));
