import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Thunk, Dispatch } from '../../store';

import { ThemeMode, FontMode } from 'front-components/src/constants';

interface UIState {
  themeMode: ThemeMode;
  fontMode: FontMode;
}

const initialState: UIState = {
  themeMode: ThemeMode.LIGHT,
  fontMode: FontMode.NORMAL,
};

// Slice
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      const { payload } = action;
      state.themeMode = payload;
    },
  },
});

// Reducers
export default uiSlice.reducer;

// Selectors
export const uiSelector = (state: RootState) => state.ui;

// Actions
const { setThemeMode } = uiSlice.actions;

// Thunks
export const updateThemeMode =
  (themeMode: ThemeMode): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setThemeMode(themeMode));
  };
