import MenuSectionItem from '@myneva-portals/models/src/models/navigation/menu-section-item';
import PageKey from '@myneva-portals/models/src/models/page-key';
import AccordionState from './navigation/accordion-state';

class PathUtils {
  createLink(pageName: string): string {
    return `/${pageName}`;
  }

  getKeyFromLink(link: string): PageKey {
    try {
      return link as PageKey;
    } catch (error) {
      return PageKey.NOT_FOUND;
    }
  }

  /**
   * [ A, B ] -> "/A/B"
   * @param pageNames
   * @returns
   */
  createRoute(pageNames: string[]): string {
    const path: string = pageNames
      .map((pageName: string) => this.createLink(pageName))
      .join('');

    return path;
  }

  /**
   * "/A/B" -> [ A, B ]
   * @param path
   * @returns
   */
  getPageNames(path: string): string[] {
    const pages: string[] = path.split('/').filter((page: string) => !!page);
    return pages;
  }

  /**
   * [ A, B ] -> [ "/A", "/A/B" ]
   * @param pageNames
   * @returns
   */
  createLinks(pageNames: string[]): string[] {
    const links: string[] = [];

    for (let i = 0; i < pageNames.length; ++i) {
      const path: string = this.createRoute(pageNames.slice(0, i + 1));
      links.push(path);
    }

    return links;
  }

  getPageLinksFromPath(path: string): string[] {
    const pageNames: string[] = this.getPageNames(path);
    return this.createLinks(pageNames);
  }

  getSectionsToExpand(
    selectedMenuItemKey: PageKey,
    menuItems: MenuSectionItem[]
  ): PageKey[] {
    const accordionState: AccordionState = new AccordionState(menuItems);
    return accordionState.getSectionsToExpand(selectedMenuItemKey);
  }

  getSelectedMenuItem(pathName: string): PageKey {
    const links: string[] = this.getPageLinksFromPath(pathName);
    return links.length ? this.getKeyFromLink(links[0]) : PageKey.HOME;
  }

  getBreadcrumbs(pathName: string): PageKey[] {
    const links: string[] = this.getPageLinksFromPath(pathName);
    return links.length
      ? links.map((link: string) => pathUtils.getKeyFromLink(link))
      : [PageKey.HOME];
  }
}

const pathUtils = new PathUtils();
export default pathUtils;
