import '@myneva-portals/utils/src/styles/global.css';
import './src/languages/init-languages';

import React from 'react';
import { Provider } from 'react-redux';
import { NavigationWrapper } from './src/components/navigation-wrapper/navigation-wrapper';
import { SessionWrapper } from './src/components/session-wrapper/session-wrapper';
import { UiWrapper } from './src/components/ui-wrapper/ui-wrapper';
import store from './src/state/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getSlicedPathName } from '@myneva-portals/utils/src/utils/navigation/path-name';

export const wrapRootElement = ({ element }) => {
  return element;
};

export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <UiWrapper>
        <SessionWrapper>
          <NavigationWrapper
            pathName={getSlicedPathName(props.location.pathname)}
          >
            <QueryClientProvider client={queryClient}>
              {element}
            </QueryClientProvider>
          </NavigationWrapper>
        </SessionWrapper>
      </UiWrapper>
    </Provider>
  );
};
