import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Reseller from '@myneva-portals/models/src/models/reseller/reseller';
import { RootState, Thunk, Dispatch } from '../../store';

interface ResellerState {
  resellers: Reseller[];
}

const initialState: ResellerState = {
  resellers: undefined,
};

export const resellersSlice = createSlice({
  name: 'resellers',
  initialState,
  reducers: {
    setResellers: (state, action: PayloadAction<Reseller[]>) => {
      const { payload } = action;
      state.resellers = payload;
    },
  },
});

export default resellersSlice.reducer;

export const resellersSelector = (state: RootState) => state.resellers;

const { setResellers } = resellersSlice.actions;

export const updateResellers =
  (resellers: Reseller[]): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setResellers(resellers));
  };
