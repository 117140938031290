import React, { useEffect, useRef } from 'react';
import createActivityDetector from 'activity-detector';
import { ActivityDetectorOptions } from '@myneva-portals/models/src/models/activity-detector/activity-detector.options';

const startActivityDetector = (
  options: ActivityDetectorOptions,
  callback: () => void
) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const activityDetector = createActivityDetector(options);

    activityDetector.on('active', () => {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    });

    return () => activityDetector.stop();
  }, []);
};

interface ActivityDetectorWrapperProps {
  callback: () => void;
  timeToIdle: number;
  children: React.ReactNode;
}

export const ActivityDetectorWrapper: React.FC<ActivityDetectorWrapperProps> = (
  props: ActivityDetectorWrapperProps
) => {
  startActivityDetector(
    { timeToIdle: props.timeToIdle, ignoredEventsWhenIdle: [] },
    props.callback
  );

  return <div>{props.children}</div>;
};
