import { navigationSlice } from './index';
import PageKey from '@myneva-portals/models/src/models/page-key';
import store, { Dispatch, Thunk } from '../../store';
import pathUtils from '@myneva-portals/utils/src/utils/path-utils';
import { MenuSection } from '@myneva-portals/models/src/models/navigation/menu-section';
import { getMenuStructure } from '../../../navigation/current-menu';

const { setExpandedSections } = navigationSlice.actions;

export const toggleExpandedSections =
  (sectionName: PageKey): Thunk =>
  (dispatch: Dispatch) => {
    const { expandedSections } = store.getState().navigation;
    const sectionIdx: number = expandedSections.indexOf(sectionName);

    const sections: PageKey[] = Object.assign([], expandedSections);

    if (sectionIdx !== -1) {
      sections.splice(sectionIdx, 1);
    } else {
      sections.push(sectionName);
    }

    dispatch(setExpandedSections(sections));
  };

export const updateExpandedSections =
  (selectedMenuItemKey: PageKey): Thunk =>
  (dispatch: Dispatch) => {
    const { expandedSections, menuType } = store.getState().navigation;

    const sectionsToExpand: PageKey[] = [];
    const menu: MenuSection[] = getMenuStructure(menuType);

    for (let i = 0; i < menu.length; ++i) {
      const sections = pathUtils.getSectionsToExpand(
        selectedMenuItemKey,
        menu[i].items
      );
      if (sections) {
        sectionsToExpand.push(...sections);
      }
    }

    const sections: PageKey[] = Object.assign([], expandedSections);

    for (let i = 0; i < sectionsToExpand.length; ++i) {
      if (!sections.includes(sectionsToExpand[i])) {
        sections.push(sectionsToExpand[i]);
      }
    }

    dispatch(setExpandedSections(sections));
  };
