import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITemplateDetails } from '@myneva-portals/models/src/models/template/template-details';
import { Dispatch, RootState, Thunk } from '../../store';

interface TemplateDetailsState {
  templateDetails: ITemplateDetails[];
}

const initialState: TemplateDetailsState = {
  templateDetails: null,
};

export const templateDetailsSlice = createSlice({
  name: 'templateDetails',
  initialState,
  reducers: {
    setTemplateDetails: (state, action: PayloadAction<ITemplateDetails[]>) => {
      const { payload } = action;
      state.templateDetails = payload;
    },
  },
});

export default templateDetailsSlice.reducer;

export const templateDetailsSelector = (state: RootState) =>
  state.templateDetails;

const { setTemplateDetails } = templateDetailsSlice.actions;

export const updateTemplateDetails =
  (templateDetails: ITemplateDetails[]): Thunk =>
  (dispatch: Dispatch) =>
    dispatch(setTemplateDetails(templateDetails));
