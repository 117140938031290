enum PageKey {
  HOME = '/',
  DASHBOARD = '/dashboard',
  RESELLERS = '/resellers',
  INVOICES = '/invoices',
  REPORTS = '/reports',
  USERS = '/users',
  ADD_CORE_ADMIN = '/users/add-core-admin',
  PERMISSIONS = '/permissions',
  LICENSES = '/licenses',
  SETTINGS = '/settings',
  NOT_FOUND = '/404',
  LANGUAGES = '/lang',
  GENERAL_SETTINGS = '/general-settings',
  APPEARANCE_SETTINGS = '/appearance-settings',
  EMAILS_SETTINGS = '/emails-settings',
  SMS_SETTINGS = '/sms-settings',
  RESELLERS_ADD = '/resellers/add-reseller',
  USER_SETTINGS_OVERVIEW = '/user-settings',
  USER_SETTINGS_GENERAL = '/user-settings/general',
  USER_SETTINGS_DISPLAY = '/user-settings/display',
  USER_SETTINGS_LANGUAGE = '/user-settings/language',
  USER_SETTINGS_NOTIFICATION = '/user-settings/notifications',
  USER_SETTINGS_SESSION = '/user-settings/sessions',
  USER_SETTINGS_PRIVACY = '/user-settings/privacy',
  USER_SETTINGS_GENERAL_EDIT = '/user-settings/general/edit',
  MY_ORGANIZATION = '/my-organization',
  ADMINS = '/admins',
  ADD_ADMIN = '/admins/add-customer-admin',
  STANDARD_USERS = '/standard-users',
  ADD_STANDARD_USER = '/standard-users/add-standard-user',
  ADD_CUSTOMER = '/customers/add-customer',
  ORGANIZATION_SETTINGS = '/organization-settings',
  CUSTOMERS = '/customers',
  ADD_ORGANIZATION_LEVEL = '/organization-settings/add-level',
  ADD_ORGANIZATION_SUBLEVEL = '/organization-settings/add-sublevel',
  ADD_ASSIGN_LEVEL = '/organization-settings/add-level/assign-users',
  EDIT_ASSIGN_LEVEL = '/organization-settings/edit-level/assign-users',
}

export default PageKey;
