export class SessionFlow {
  private isRedirecting = false;

  constructor(private url: string) {}

  get canCheckSession(): boolean {
    return !this.isRedirecting;
  }

  redirect(): void {
    this.isRedirecting = true;
    window.location.href = this.url;
  }
}
