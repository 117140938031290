import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageKey from '@myneva-portals/models/src/models/page-key';
import {
  updateMenuSelection,
  updateBreadcrumbs,
  navigationSelector,
} from '../../state/reducers/navigation';
import pathUtils from '@myneva-portals/utils/src/utils/path-utils';
import { updateExpandedSections } from '../../state/reducers/navigation/thunks';
import { MenuType } from '@myneva-portals/models/src/models/navigation/menu-type';

export interface NavigationWrapperProps {
  pathName: string;
  children: React.ReactNode;
}

export const NavigationWrapper: React.FC<NavigationWrapperProps> = (
  props: NavigationWrapperProps
) => {
  const dispatch = useDispatch();

  const { menuType } = useSelector(navigationSelector);

  const getSelectedMenuItem = (pathName: string): PageKey => {
    const links: string[] = pathUtils.getPageLinksFromPath(pathName);
    return links.length
      ? pathUtils.getKeyFromLink(
          links[menuType === MenuType.MAIN_MENU ? 0 : links.length - 1]
        )
      : PageKey.HOME;
  };

  const getBreadcrumbs = (pathName: string): PageKey[] => {
    const links: string[] = pathUtils.getPageLinksFromPath(pathName);
    return links.length
      ? links.map((link: string) => pathUtils.getKeyFromLink(link))
      : [PageKey.HOME];
  };

  useEffect(() => {
    const selectedMenuItem: PageKey = getSelectedMenuItem(props.pathName);
    const currentBreadcrumbs: PageKey[] = getBreadcrumbs(props.pathName);

    dispatch(updateMenuSelection(selectedMenuItem));
    dispatch(updateBreadcrumbs(currentBreadcrumbs));
    dispatch(updateExpandedSections(selectedMenuItem));
  }, [props.pathName]);

  return <div>{props.children}</div>;
};
