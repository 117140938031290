import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userService } from '@myneva-portals/services/src/services/';
import { useEffect, useState } from 'react';
import {
  sessionSelector,
  updateLastUserActivityDateInMs,
  updateSessionEndDate,
  updateSessionId,
  updateUserData,
} from '../../state/reducers/session';
import { useTranslation } from 'react-i18next';
import {
  LoadingWrapper,
  SessionAlertModal,
} from 'front-components/src/components';
import { updateIsLogedIn } from '../../state/reducers/session/index';
import { User } from '@myneva-portals/models/src/models/user/user';
import { SessionFlow } from './session-flow';
import { ActivityDetectorWrapper } from './activity-detector-wrapper';
import { useInterval } from 'front-components/src/tools/timers';

export interface SessionWrapperProps {
  children: React.ReactNode;
}

export const SessionWrapper: React.FC<SessionWrapperProps> = (
  props: SessionWrapperProps
) => {
  const dispatch = useDispatch();
  const { isLogedId, sessionEndDate } = useSelector(sessionSelector);

  const [showAlert, setShowAlert] = useState(false);

  const { t } = useTranslation();

  const sessionFlow: SessionFlow = new SessionFlow(userService.loginPageUrl);

  const resetSession = () => {
    userService.clearSessionId();
    sessionFlow.redirect();
  };

  const getUserData = async (sessionId: string) => {
    const userData: User = await userService.getUserData();

    dispatch(updateSessionId(sessionId));
    dispatch(
      updateSessionEndDate(new Date().getTime() + userService.sessonTimeInMs)
    );
    dispatch(updateIsLogedIn(true));

    if (userData) {
      dispatch(updateUserData(userData.name, userData.type, userData.sub));
    }

    return userData;
  };

  const checkSession = async (): Promise<void> => {
    const sessionId: string = await userService.getSessionId();
    if (!sessionId) {
      sessionFlow.redirect();
      return;
    }

    try {
      const user: User = await getUserData(sessionId);
      if (user) {
        if (!haveUserAccess(user.type)) {
          redirectToUserPortal();
        }
      }
    } catch (error) {
      console.log(error);
      resetSession();
    }
  };

  const haveUserAccess = (userType: string): boolean => {
    const url = window && window.location && window.location.href;

    const resellerAccess: boolean =
      userType.includes('RESELLER') && url.includes(process.env.RESELLER_URL);
    const cutomerAccess: boolean =
      userType.includes('CUSTOMER') && url.includes(process.env.CUSTOMER_URL);
    const coreAdminAccess: boolean =
      userType.includes('ADMIN_CORE') &&
      url.includes(process.env.CORE_ADMIN_URL);

    return resellerAccess || cutomerAccess || coreAdminAccess;
  };

  const redirectToUserPortal = (): void => {
    window.location.href =
      process.env.WEB_GATEWAY_URL + '/oauth2/authorization/web-gateway';
  };

  useEffect(() => {
    if (sessionFlow.canCheckSession) {
      checkSession();
    }
  }, []);

  useInterval(() => {
    if (sessionEndDate) {
      const diffInMs: number = sessionEndDate - new Date().getTime();
      if (diffInMs <= 60000 && !showAlert) {
        setShowAlert(true);
      }
    }
  }, 1000);

  const updateSessionTime = (): void => {
    if (sessionEndDate) {
      const currentTimeValueInMs: number = new Date().getTime();
      const diff: number = sessionEndDate - currentTimeValueInMs;
      if (diff < userService.sessonTimeInMs) {
        const newSessionEndDateInMs: number =
          currentTimeValueInMs + userService.sessonTimeInMs;
        dispatch(updateSessionEndDate(newSessionEndDateInMs));
      } else {
        dispatch(updateLastUserActivityDateInMs(currentTimeValueInMs));
      }
    }
  };

  const handleModalClose = () => {
    const currentTimeValueInMs: number = new Date().getTime();
    const newSessionEndDateInMs: number =
      currentTimeValueInMs + userService.sessonTimeInMs;
    dispatch(updateSessionEndDate(newSessionEndDateInMs));
    setShowAlert(false);
  };

  const handleLogout = () => {
    userService.logout();
  };

  return (
    <div>
      <LoadingWrapper
        isLoading={!isLogedId}
        content={
          <>
            <ActivityDetectorWrapper
              callback={updateSessionTime}
              timeToIdle={1000}
            >
              {props.children}
            </ActivityDetectorWrapper>
            <SessionAlertModal
              open={showAlert}
              header={t('SESSION_MODAL_HEADER')}
              content={t('SESSION_MODAL_CONTENT1')}
              contentNextLine={t('SESSION_MODAL_CONTENT2')}
              labelPrimaryButton={t('Keep me signed in')}
              labelSecondaryButton={t('Sign me out')}
              dataCy={'session-alert-modal-core-admin-portal'}
              onClickPrimaryButton={handleModalClose}
              onClickSecondaryButton={handleLogout}
            />
          </>
        }
      />
    </div>
  );
};
