import { User } from '@myneva-portals/models/src/models/user/user';
import { UserService } from './user-service';
import axios from 'axios';
import Cookies from 'js-cookie';

class RestUserService implements UserService {
  loginPageUrl: string =
    process.env.WEB_GATEWAY_URL + '/oauth2/authorization/web-gateway';
  sessonTimeInMs: number = 1000 * 60 * 5;

  async getSessionId(): Promise<string> {
    const userSession: string =
      Cookies.get('SESSION') || Cookies.get('USER_SESSION');
    if (!userSession) {
      try {
        const userData: User = await this.getUserData();
        Cookies.set('USER_SESSION', `user session: ${userData.name}`);
        return userData.name;
      } catch (error) {
        console.log('no cookie', error);
      }
    }

    console.log('session cookie:', userSession);

    return userSession;
  }

  async getUserData(): Promise<User> {
    const response = await axios.get(process.env.WEB_GATEWAY_URL + '/idtoken', {
      withCredentials: true,
      responseType: 'json',
    });

    console.log('getUserData response:', response);

    // todo: resposnse without cookie

    if (typeof response.data === 'string' || response.data instanceof String) {
      throw new Error('Invalid data type');
    }

    return {
      id: response.data.sub,
      ...response.data,
    };
  }

  async logout(): Promise<void> {
    const response = await axios.post(
      process.env.WEB_GATEWAY_URL + '/logout',
      {},
      {
        withCredentials: true,
      }
    );

    console.log('logout response:', response);

    if (response.status === 200) {
      this.clearSessionId();

      const redirectUrl: string = response.headers.location;
      window.location.href = redirectUrl;
    }
  }

  clearSessionId(): void {
    Cookies.remove('USER_SESSION');
    Cookies.remove('SESSION');
  }

  async getUserResellerId(userId: string): Promise<string> {
    try {
      const response = await axios.get(
        process.env.WEB_GATEWAY_URL + `/reseller/admin/${userId}`,
        {
          withCredentials: true,
        }
      );
      return response.data.reseller_id;
    } catch (error) {
      console.log(error);
    }
  }
}

export const restUserService = new RestUserService();
