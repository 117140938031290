import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Thunk, Dispatch } from '../../store';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { MenuType } from '@myneva-portals/models/src/models/navigation/menu-type';

interface NavigationState {
  menuType: MenuType;
  selectedMenuItem: PageKey;
  breadcrumbs: PageKey[];
  expandedSections: PageKey[];
  lastSelectedMenuItem?: PageKey;
}

const initialState: NavigationState = {
  menuType: MenuType.MAIN_MENU,
  selectedMenuItem: PageKey.HOME,
  breadcrumbs: [PageKey.HOME],
  expandedSections: undefined,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setSelectedMenuItem: (state, action: PayloadAction<PageKey>) => {
      const { payload } = action;
      state.selectedMenuItem = payload;
    },
    setBreadcrumbs: (state, action: PayloadAction<PageKey[]>) => {
      const { payload } = action;
      state.breadcrumbs = payload;
    },
    setExpandedSections: (state, action: PayloadAction<PageKey[]>) => {
      const { payload } = action;
      state.expandedSections = payload;
    },
    setMenuStructure: (state, action: PayloadAction<MenuType>) => {
      const { payload } = action;
      state.menuType = payload;
    },
    setLastSelectedMenuItem: (state, action: PayloadAction<PageKey>) => {
      const { payload } = action;
      state.lastSelectedMenuItem = payload;
    },
  },
});

export default navigationSlice.reducer;

export const navigationSelector = (state: RootState) => state.navigation;

const {
  setSelectedMenuItem,
  setBreadcrumbs,
  setMenuStructure,
  setLastSelectedMenuItem,
} = navigationSlice.actions;

export const updateMenuSelection =
  (menuItem: PageKey): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setSelectedMenuItem(menuItem));
  };

export const updateBreadcrumbs =
  (breadcrumbs: PageKey[]): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setBreadcrumbs(breadcrumbs));
  };

export const updateMenuStructure =
  (type?: MenuType): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setMenuStructure(type));
  };

export const updateLastSelectedMenuItem =
  (menuItem: PageKey): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setLastSelectedMenuItem(menuItem));
  };
