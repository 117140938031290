import { MenuType } from '@myneva-portals/models/src/models/navigation/menu-type';
import { initialMenuStructure } from '../state/reducers/navigation/initial-menu-structure';
import { userSettingsMenuStructure } from '../state/reducers/navigation/user-settings-menu-structure';

export function getMenuStructure(type?: MenuType) {
  if (type === undefined) {
    return initialMenuStructure;
  }

  switch (type) {
    case MenuType.MAIN_MENU:
      return initialMenuStructure;
    case MenuType.USER_SETTINGS_MENU:
      return userSettingsMenuStructure;
  }
}
