import CustomerDetails from '@myneva-portals/models/src/models/customer/customer-details';
import CustomerDetailsDTO from '@myneva-portals/models/src/models/customer/customer-details-dto';
import { CustomerDTO } from '@myneva-portals/models/src/models/customer/customer-dto';
import { CustomerResponse } from '@myneva-portals/models/src/models/customer/customer-response';
import { CustomerResponseDTO } from '@myneva-portals/models/src/models/customer/customer-response-dto';
import { FilterParamsDTO } from '@myneva-portals/models/src/models/filter-params-dto';
import { IUser } from '@myneva-portals/models/src/models/user/iuser';
import MyOrganization from '@myneva-portals/models/src/models/my-organization/my-organization';
import MyOrganizationDTO from '@myneva-portals/models/src/models/my-organization/my-organization-dto';
import Reseller from '@myneva-portals/models/src/models/reseller/reseller';
import ResellerDetails from '@myneva-portals/models/src/models/reseller/reseller-details';
import ResellerDetailsDTO from '@myneva-portals/models/src/models/reseller/reseller-details-dto';
import { ResellerDTO } from '@myneva-portals/models/src/models/reseller/reseller-dto';
import { SortParamsDTO } from '@myneva-portals/models/src/models/sort-params-dto';
import { IUserDTO } from '@myneva-portals/models/src/models/user/user-dto';
import { UsersResponse } from '@myneva-portals/models/src/models/user/users-response';
import { UsersResponseDTO } from '@myneva-portals/models/src/models/user/users-response-dto';
import { FilterType } from 'front-components/src/constants';
import {
  FilterParams,
  SortParams,
} from 'front-components/src/components/TablePagination/search-params';
import { MyGeneralSettings } from '@myneva-portals/models/src/models/my-organization/my-general-settings';
import { MyGeneralSettingsDto } from '@myneva-portals/models/src/models/dto/my-general-settings-dto';
import { MySettings } from '@myneva-portals/models/src/models/my-organization/my-settings';
import { MySettingsDto } from '@myneva-portals/models/src/models/dto/my-settings-dto';

export const mapCustomerToAppModel = (
  customer: CustomerResponseDTO
): CustomerResponse => {
  const customers = customer.items.map((customer: CustomerDTO) => {
    return {
      id: customer.id,
      name: customer.name,
      invocing: customer.invoicing_nr,
      intAccNumber: customer.internal_accounting_nr,
      state: customer.state,
      creationDate: customer.created_at,
      lastActive: customer.last_active,
      isActive: customer.is_enabled,
      isFav: false,
      avatar: customer.logo,
    };
  });

  return {
    totalCount: customer.total_count,
    customers,
  };
};

export const mapCustomerDetailsToAppModel = (
  reseller: CustomerDetailsDTO
): CustomerDetails => {
  return {
    header: reseller.name,
    avatar: reseller.logo,
    isActive: reseller.is_enabled,
    reseller: {
      General: {
        id: reseller.reseller_id,
        companyName: reseller.name,
        invocingNumber: reseller.invoicing_nr,
        internalAccountingNumber: reseller.internal_accounting_nr,
      },
      Address: {
        country: reseller.country,
        address1: reseller.address_one,
        address2: reseller.address_two,
        postcode: reseller.post_code,
        city: reseller.city,
        state: reseller.state,
        BillingAddress: {
          country: reseller.billing_country,
          address1: reseller.billing_address_one,
          address2: reseller.billing_address_two,
          postcode: reseller.billing_post_code,
          city: reseller.billing_city,
          state: reseller.billing_state,
        },
      },
      Contacts: {
        techSupport: {
          id: reseller.tech_contact.id,
          email: reseller.tech_contact.email,
          phone: reseller.tech_contact.phone,
          faxNumber: reseller.tech_contact.fax,
          firstName: reseller.tech_contact.first_name,
          surname: reseller.tech_contact.last_name,
          note: reseller.tech_contact.note,
        },
        accountAdmin: {
          id: reseller.admin_contact.uuid,
          email: reseller.admin_contact.email,
          phone: reseller.admin_contact.phone,
          faxNumber: reseller.admin_contact.fax,
          firstName: reseller.admin_contact.first_name,
          surname: reseller.admin_contact.last_name,
          note: reseller.admin_contact.note,
        },
        billingContact: {
          id: reseller.reseller_id,
          email: reseller.billing_contact.email,
          phone: reseller.billing_contact.phone,
          faxNumber: reseller.billing_contact.fax,
          firstName: reseller.billing_contact.first_name,
          surname: reseller.billing_contact.last_name,
          note: reseller.billing_contact.note,
        },
      },
    },
  };
};

export const mapMyOrganizationToAppModel = (
  reseller: MyOrganizationDTO
): MyOrganization => {
  return {
    header: reseller.name,
    avatar: reseller.logo,
    isActive: reseller.is_enabled,
    reseller: {
      General: {
        id: reseller.reseller_id,
        companyName: reseller.name,
        invocingNumber: reseller.invoicing_nr,
        internalAccountingNumber: reseller.internal_accounting_nr,
      },
      Address: {
        country: reseller.country,
        address1: reseller.address_one,
        address2: reseller.address_two,
        postcode: reseller.post_code,
        city: reseller.city,
        state: reseller.state,
        BillingAddress: {
          country: reseller.billing_country,
          address1: reseller.billing_address_one,
          address2: reseller.billing_address_two,
          postcode: reseller.billing_post_code,
          city: reseller.billing_city,
          state: reseller.billing_state,
        },
      },
      Contacts: {
        techSupport: {
          id: reseller.tech_contact.id,
          email: reseller.tech_contact.email,
          phone: reseller.tech_contact.phone,
          faxNumber: reseller.tech_contact.fax,
          firstName: reseller.tech_contact.first_name,
          surname: reseller.tech_contact.last_name,
          note: reseller.tech_contact.note,
        },
        accountAdmin: {
          id: reseller.admin_contact.id,
          email: reseller.admin_contact.email,
          phone: reseller.admin_contact.phone,
          faxNumber: reseller.admin_contact.fax,
          firstName: reseller.admin_contact.first_name,
          surname: reseller.admin_contact.last_name,
          note: reseller.admin_contact.note,
        },
        billingContact: {
          id: reseller.reseller_id,
          email: reseller.billing_contact.email,
          phone: reseller.billing_contact.phone,
          faxNumber: reseller.billing_contact.fax,
          firstName: reseller.billing_contact.first_name,
          surname: reseller.billing_contact.last_name,
          note: reseller.billing_contact.note,
        },
      },
    },
  };
};

export const mapMyCustomerOrganizationToAppModel = (
  reseller: MyOrganizationDTO
): MyOrganization => {
  return {
    header: reseller.name,
    avatar: reseller.logo,
    isActive: reseller.is_enabled,
    reseller: {
      General: {
        id: reseller.id,
        companyName: reseller.name,
        invocingNumber: reseller.invoicing_nr,
        internalAccountingNumber: reseller.internal_accounting_nr,
      },
      Address: {
        country: reseller.country,
        address1: reseller.address_one,
        address2: reseller.address_two,
        postcode: reseller.post_code,
        city: reseller.city,
        state: reseller.state,
        BillingAddress: {
          country: reseller.billing_country,
          address1: reseller.billing_address_one,
          address2: reseller.billing_address_two,
          postcode: reseller.billing_post_code,
          city: reseller.billing_city,
          state: reseller.billing_state,
        },
      },
      Contacts: {
        techSupport: {
          id: reseller.tech_contact.id,
          email: reseller.tech_contact.email,
          phone: reseller.tech_contact.phone,
          faxNumber: reseller.tech_contact.fax,
          firstName: reseller.tech_contact.first_name,
          surname: reseller.tech_contact.last_name,
          note: reseller.tech_contact.note,
        },
        accountAdmin: {
          id: reseller.admin_contact.id,
          email: reseller.admin_contact.email,
          phone: reseller.admin_contact.phone,
          faxNumber: reseller.admin_contact.fax,
          firstName: reseller.admin_contact.first_name,
          surname: reseller.admin_contact.last_name,
          note: reseller.admin_contact.note,
        },
        billingContact: {
          id: reseller.reseller_id,
          email: reseller.billing_contact.email,
          phone: reseller.billing_contact.phone,
          faxNumber: reseller.billing_contact.fax,
          firstName: reseller.billing_contact.first_name,
          surname: reseller.billing_contact.last_name,
          note: reseller.billing_contact.note,
        },
      },
    },
  };
};

export const mapCustomerToDtoModel = (
  reseller: CustomerDetails,
  userResellerId?: number
): CustomerDetailsDTO => {
  return {
    reseller_id: userResellerId,
    name: reseller.reseller.General.companyName,
    logo: reseller.avatar,
    invoicing_nr: reseller.reseller.General.invocingNumber,
    internal_accounting_nr: reseller.reseller.General.internalAccountingNumber,
    country: reseller.reseller.Address.country,
    address_one: reseller.reseller.Address.address1,
    address_two: reseller.reseller.Address.address2,
    post_code: reseller.reseller.Address.postcode,
    city: reseller.reseller.Address.city,
    state: reseller.reseller.Address.state,
    billing_country: reseller.reseller.Address.BillingAddress.country,
    billing_address_one: reseller.reseller.Address.BillingAddress.address1,
    billing_address_two: reseller.reseller.Address.BillingAddress.address2,
    billing_post_code: reseller.reseller.Address.BillingAddress.postcode,
    billing_city: reseller.reseller.Address.BillingAddress.city,
    billing_state: reseller.reseller.Address.BillingAddress.state,
    tech_contact: {
      id: reseller.reseller.Contacts.techSupport.id,
      type: 'tech',
      email: reseller.reseller.Contacts.techSupport.email,
      phone: reseller.reseller.Contacts.techSupport.phone,
      fax: reseller.reseller.Contacts.techSupport.faxNumber,
      first_name: reseller.reseller.Contacts.techSupport.firstName,
      last_name: reseller.reseller.Contacts.techSupport.surname,
      note: reseller.reseller.Contacts.techSupport.note,
    },
    admin_contact: {
      id: reseller.reseller.Contacts.accountAdmin.id,
      type: 'admin',
      email: reseller.reseller.Contacts.accountAdmin.email,
      phone: reseller.reseller.Contacts.accountAdmin.phone,
      fax: reseller.reseller.Contacts.accountAdmin.faxNumber,
      first_name: reseller.reseller.Contacts.accountAdmin.firstName,
      last_name: reseller.reseller.Contacts.accountAdmin.surname,
      note: reseller.reseller.Contacts.accountAdmin.note,
      light_mode: true,
      language: 'EN',
      timezone: 'Europe/Berlin',
    },
    billing_contact: {
      id: reseller.reseller.Contacts.billingContact.id,
      type: 'billing',
      email: reseller.reseller.Contacts.billingContact.email,
      phone: reseller.reseller.Contacts.billingContact.phone,
      fax: reseller.reseller.Contacts.billingContact.faxNumber,
      first_name: reseller.reseller.Contacts.billingContact.firstName,
      last_name: reseller.reseller.Contacts.billingContact.surname,
      note: reseller.reseller.Contacts.billingContact.note,
    },
    is_enabled: true,
  };
};

export const mapResellersToAppModel = (reseller: ResellerDTO[]): Reseller[] => {
  return reseller.map((rslr: ResellerDTO) => {
    return {
      id: rslr.id,
      name: rslr.name,
      invocing: rslr.invoicing_nr,
      intAccNumber: rslr.internal_accounting_nr,
      avatar: rslr.logo,
      isActive: rslr.is_enabled,
    };
  });
};

export const mapResellersToDetailsAppModel = (
  reseller: ResellerDetailsDTO
): ResellerDetails => {
  return {
    header: reseller.name,
    avatar: reseller.logo,
    isActive: reseller.is_enabled,
    reseller: {
      General: {
        id: reseller.id,
        companyName: reseller.name,
        invocingNumber: reseller.invoicing_nr,
        internalAccountingNumber: reseller.internal_accounting_nr,
      },
      Address: {
        country: reseller.country,
        address1: reseller.address_one,
        address2: reseller.address_two,
        postcode: reseller.post_code,
        city: reseller.city,
        state: reseller.state,
        BillingAddress: {
          country: reseller.billing_country,
          address1: reseller.billing_address_one,
          address2: reseller.billing_address_two,
          postcode: reseller.billing_post_code,
          city: reseller.billing_city,
          state: reseller.billing_state,
        },
      },
      Contacts: {
        techSupport: {
          id: reseller.tech_contact.id,
          email: reseller.tech_contact.email,
          phone: reseller.tech_contact.phone,
          faxNumber: reseller.tech_contact.fax,
          firstName: reseller.tech_contact.first_name,
          surname: reseller.tech_contact.last_name,
          note: reseller.tech_contact.note,
        },
        accountAdmin: {
          id: reseller.admin_contact && reseller.admin_contact.uuid,
          email: reseller.admin_contact && reseller.admin_contact.email,
          phone: reseller.admin_contact && reseller.admin_contact.phone,
          faxNumber: reseller.admin_contact && reseller.admin_contact.fax,
          firstName:
            reseller.admin_contact && reseller.admin_contact.first_name,
          surname: reseller.admin_contact && reseller.admin_contact.last_name,
          note: reseller.admin_contact && reseller.admin_contact.note,
        },
        billingContact: {
          id: reseller.id,
          email: reseller.billing_contact.email,
          phone: reseller.billing_contact.phone,
          faxNumber: reseller.billing_contact.fax,
          firstName: reseller.billing_contact.first_name,
          surname: reseller.billing_contact.last_name,
          note: reseller.billing_contact.note,
        },
      },
    },
  };
};

export const mapResellersToDtoModel = (
  reseller: ResellerDetails
): ResellerDetailsDTO => {
  return {
    id: reseller.reseller.General.id,
    name: reseller.reseller.General.companyName,
    logo: reseller.avatar,
    invoicing_nr: reseller.reseller.General.invocingNumber,
    internal_accounting_nr: reseller.reseller.General.internalAccountingNumber,
    country: reseller.reseller.Address.country,
    address_one: reseller.reseller.Address.address1,
    address_two: reseller.reseller.Address.address2,
    post_code: reseller.reseller.Address.postcode,
    city: reseller.reseller.Address.city,
    state: reseller.reseller.Address.state,
    billing_country: reseller.reseller.Address.BillingAddress.country,
    billing_address_one: reseller.reseller.Address.BillingAddress.address1,
    billing_address_two: reseller.reseller.Address.BillingAddress.address2,
    billing_post_code: reseller.reseller.Address.BillingAddress.postcode,
    billing_city: reseller.reseller.Address.BillingAddress.city,
    billing_state: reseller.reseller.Address.BillingAddress.state,
    tech_contact: {
      id: reseller.reseller.Contacts.techSupport.id,
      reseller_id: reseller.reseller.General.id,
      type: 'tech',
      email: reseller.reseller.Contacts.techSupport.email,
      phone: reseller.reseller.Contacts.techSupport.phone,
      fax: reseller.reseller.Contacts.techSupport.faxNumber,
      first_name: reseller.reseller.Contacts.techSupport.firstName,
      last_name: reseller.reseller.Contacts.techSupport.surname,
      note: reseller.reseller.Contacts.techSupport.note,
    },
    billing_contact: {
      id: reseller.reseller.Contacts.billingContact.id,
      reseller_id: reseller.reseller.General.id,
      type: 'billing',
      email: reseller.reseller.Contacts.billingContact.email,
      phone: reseller.reseller.Contacts.billingContact.phone,
      fax: reseller.reseller.Contacts.billingContact.faxNumber,
      first_name: reseller.reseller.Contacts.billingContact.firstName,
      last_name: reseller.reseller.Contacts.billingContact.surname,
      note: reseller.reseller.Contacts.billingContact.note,
    },
  };
};

export const mapAddResellerToDtoModel = (
  reseller: ResellerDetails
): ResellerDetailsDTO => {
  return {
    id: reseller.reseller.General.id,
    name: reseller.reseller.General.companyName,
    logo: reseller.avatar,
    invoicing_nr: reseller.reseller.General.invocingNumber,
    internal_accounting_nr: reseller.reseller.General.internalAccountingNumber,
    country: reseller.reseller.Address.country,
    address_one: reseller.reseller.Address.address1,
    address_two: reseller.reseller.Address.address2,
    post_code: reseller.reseller.Address.postcode,
    city: reseller.reseller.Address.city,
    state: reseller.reseller.Address.state,
    billing_country: reseller.reseller.Address.BillingAddress.country,
    billing_address_one: reseller.reseller.Address.BillingAddress.address1,
    billing_address_two: reseller.reseller.Address.BillingAddress.address2,
    billing_post_code: reseller.reseller.Address.BillingAddress.postcode,
    billing_city: reseller.reseller.Address.BillingAddress.city,
    billing_state: reseller.reseller.Address.BillingAddress.state,
    tech_contact: {
      id: reseller.reseller.Contacts.techSupport.id,
      reseller_id: reseller.reseller.General.id,
      email: reseller.reseller.Contacts.techSupport.email,
      phone: reseller.reseller.Contacts.techSupport.phone,
      fax: reseller.reseller.Contacts.techSupport.faxNumber,
      first_name: reseller.reseller.Contacts.techSupport.firstName,
      last_name: reseller.reseller.Contacts.techSupport.surname,
      note: reseller.reseller.Contacts.techSupport.note,
    },
    admin_contact: {
      light_mode: 'true',
      language: 'EN',
      timezone: 'Europe/Berlin',
      email:
        reseller.reseller.Contacts.accountAdmin &&
        reseller.reseller.Contacts.accountAdmin.email,
      phone:
        reseller.reseller.Contacts.accountAdmin &&
        reseller.reseller.Contacts.accountAdmin.phone,
      fax:
        reseller.reseller.Contacts.accountAdmin &&
        reseller.reseller.Contacts.accountAdmin.faxNumber,
      first_name:
        reseller.reseller.Contacts.accountAdmin &&
        reseller.reseller.Contacts.accountAdmin.firstName,
      last_name:
        reseller.reseller.Contacts.accountAdmin &&
        reseller.reseller.Contacts.accountAdmin.surname,
      note:
        reseller.reseller.Contacts.accountAdmin &&
        reseller.reseller.Contacts.accountAdmin.note,
    },
    billing_contact: {
      id: reseller.reseller.Contacts.billingContact.id,
      reseller_id: reseller.reseller.General.id,
      email: reseller.reseller.Contacts.billingContact.email,
      phone: reseller.reseller.Contacts.billingContact.phone,
      fax: reseller.reseller.Contacts.billingContact.faxNumber,
      first_name: reseller.reseller.Contacts.billingContact.firstName,
      last_name: reseller.reseller.Contacts.billingContact.surname,
      note: reseller.reseller.Contacts.billingContact.note,
    },
  };
};

export const mapUsersToAppModel = (
  usersResponse: UsersResponseDTO
): UsersResponse => {
  const users: IUser[] = usersResponse.items.map((user: IUserDTO) => {
    return {
      id: user?.uuid,
      firstName: user?.first_name,
      lastName: user?.last_name,
      email: user?.email,
      phone: user?.phone,
      status: user?.status,
      type: user?.type,
      fax: user?.fax,
    };
  });

  return {
    totalCount: usersResponse.total_count,
    users,
  };
};

export const mapUserDetailsToDtoModel = (user: IUser): IUserDTO => {
  return {
    uuid: user?.id,
    email: user?.email,
    first_name: user?.firstName,
    last_name: user?.lastName,
    phone: user?.phone,
    type: user?.type,
    note: user?.note,
    fax: user?.fax,
    language: user?.language ? user.language : 'PL',
    light_mode: true,
    timezone: user?.timezone ? user.timezone : 'Europe/Berlin',
    status: user?.status,
  };
};

export const mapUserDetailsToAppModel = (user: IUserDTO): IUser => {
  return {
    id: user?.uuid,
    email: user?.email,
    fax: user?.fax,
    firstName: user?.first_name,
    lastName: user?.last_name,
    language: user?.language,
    lightmode: user?.light_mode,
    note: user?.note,
    phone: user?.phone,
    status: user?.status,
    timezone: user?.timezone,
    type: user?.type,
  };
};

export const mapCustomerFilterParamsToDTO = (
  filterParams: FilterParams[]
): FilterParamsDTO[] => {
  if (!filterParams) {
    return null;
  }

  return filterParams.map((filterParam: FilterParams) => {
    const paramDTO: FilterParamsDTO = {
      field_name: filterParam.columnName,
      type: FilterType.CONTAINS,
    };

    if (filterParam.filterType === FilterType.CONTAINS) {
      paramDTO.text = filterParam.value as string;
    } else {
      paramDTO.items = filterParam.value
        .filter((selectValue) => selectValue.checked)
        .map((selectValue) => (selectValue.name === 'Active' ? true : false));
    }
    return paramDTO;
  });
};

export const mapFilterParamsToDTO = (
  filterParams: FilterParams[]
): FilterParamsDTO[] => {
  if (!filterParams) {
    return null;
  }
  return filterParams.map((filterParam: FilterParams) => {
    const paramDTO: FilterParamsDTO = {
      field_name: filterParam.columnName,
      type: FilterType.CONTAINS,
    };

    if (filterParam.filterType === FilterType.CONTAINS) {
      paramDTO.text = filterParam.value as string;
    } else {
      paramDTO.items = filterParam.value
        .filter((selectValue) => selectValue.checked)
        .map((selectValue) => selectValue.name.toUpperCase());
    }
    if (filterParam.uuids) {
      paramDTO.uuids = filterParam.uuids;
    }
    return paramDTO;
  });
};

export const mapSortParamsToDTO = (sortParams: SortParams): SortParamsDTO[] => {
  if (!sortParams) {
    return [];
  }

  return [
    {
      field_name: sortParams.columnName,
      order: sortParams.order,
    },
  ];
};

export const mapLevelToDtoModel = (level) => {
  return {
    name: level.levelName,
    assignedUsers: level.assignedUsers ? level.assignedUsers : [],
  };
};

const userCountArray: number[] = [];
const parentIdArray: string[] = [];
const allNodesIds: string[] = [];

export const mapOrganizationToAppModel = (response: any) => {
  response.map((item, index, array) => {
    let userCounterSum: number = item.userCount;
    const userCounterSumF = (num: number) => {
      userCounterSum = userCounterSum + num;
    };

    checkHasChild(item, item.id, userCounterSumF);
    item.id = 'root'.concat(item.id.toString());

    if (index == array.length - 1) {
      item.isLastChild = true;
    } else {
      item.isLastChild = false;
    }
    item.userCounterSummed = userCounterSum;
    allNodesIds.push(item.id);
  });
  response.push(allNodesIds);
};

const checkHasChild = async (
  obj: any,
  parentId: string,
  userCounterSumF: any
) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length) {
        obj[key].map((item, index, arr) => {
          if (index == arr.length - 1) {
            item.isLastChild = true;
          } else {
            item.isLastChild = false;
          }
          item.id = item.id.toString();
          item.parentId = obj.id.toString();
          allNodesIds.push(item.id);
          userCounterSumF(item.userCount);
          checkHasChild(item, obj.id, userCounterSumF);
          const indexy = getAllIndexes(parentIdArray, item.id);
          const value =
            sumValuesByIndexes(userCountArray, indexy) + item.userCount;
          item.userCounterSummed = value;
          userCountArray.push(value);
          parentIdArray.push(obj.id);
        });
      }
    }
  }
  return obj;
};

const getAllIndexes = (arr: string[], value: string) => {
  const indexes = [];
  let i = -1;
  while ((i = arr.indexOf(value, i + 1)) != -1) {
    indexes.push(i);
  }
  return indexes;
};

const sumValuesByIndexes = (array: number[], arrayIndexes: number[]) => {
  let summedValue = 0;
  array.map((el: any, elementIndex: number) => {
    if (arrayIndexes.includes(elementIndex)) {
      summedValue = summedValue + array[elementIndex];
    }
  });
  return summedValue;
};

export const mapDtoToMySettings = (dto: MySettingsDto): MySettings => {
  return {
    general: mapDtoToMyGeneralSettings(dto.general),
  };
};

export const mapDtoToMyGeneralSettings = (
  dto: MyGeneralSettingsDto
): MyGeneralSettings => {
  return {
    avatar: dto.avatar,
    email: dto.email,
    fax: dto.fax,
    firstName: dto.first_name,
    lastName: dto.last_name,
    name: dto.first_name + ' ' + dto.last_name,
    phone: dto.phone,
    type: dto.type,
    addedDate: dto.added_date,
  };
};

export const mapGeneralSettingstoDto = (
  generalSettings: MyGeneralSettings
): MyGeneralSettingsDto => {
  return {
    avatar: generalSettings.avatar,
    email: generalSettings.email,
    fax: generalSettings.fax,
    first_name: generalSettings.firstName,
    last_name: generalSettings.lastName,
    phone: generalSettings.phone,
    type: generalSettings.type,
    added_date: generalSettings.addedDate,
  };
};
