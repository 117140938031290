import MenuSectionItem from '@myneva-portals/models/src/models/navigation/menu-section-item';
import PageKey from '@myneva-portals/models/src/models/page-key';

export default class AccordionState {
  constructor(private menuItems: MenuSectionItem[]) {}

  getSectionsToExpand(selectedMenuItemKey: PageKey): PageKey[] {
    return this.getSections(selectedMenuItemKey, this.menuItems);
  }

  private getSections(
    selectedMenuItemKey: PageKey,
    items: MenuSectionItem[],
    itemsParentKey?: PageKey
  ): PageKey[] {
    const sectionsKeysToExpand: PageKey[] = [];

    for (let i = 0; i < items.length; ++i) {
      const item: MenuSectionItem = items[i];

      if (item.items) {
        const childsSections: PageKey[] = this.getSections(
          selectedMenuItemKey,
          item.items,
          item.key
        );
        if (childsSections) {
          sectionsKeysToExpand.push(...childsSections);

          if (itemsParentKey) {
            sectionsKeysToExpand.push(itemsParentKey);
          }
        }
      }

      if (itemsParentKey && item.key === selectedMenuItemKey) {
        sectionsKeysToExpand.push(itemsParentKey);
      }
    }

    return sectionsKeysToExpand;
  }
}
