import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './EN.json';
import de from './DE.json';
import nl from './NL.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'de', 'nl'];

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  nl: {
    translation: nl,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
