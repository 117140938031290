import { IUser } from '@myneva-portals/models/src/models/iuser';
import { ITemplateDetails } from '@myneva-portals/models/src/models/template-details';
import { TemplateDTO } from '@myneva-portals/models/src/models/template-dto';
import { ITemplateRequestDetails } from '@myneva-portals/models/src/models/template-request-details';
import { IAppearanceSettingsTemplate } from '@myneva-portals/models/src/models/appearance-settings-template';
import { AppearanceSettingsTemplateRequest } from '@myneva-portals/models/src/models/appearance-settings-template-request';
import { AppearanceSettingsTemplateDTO } from '@myneva-portals/models/src/models/appearance-settings-template-dto';
import { TemplateRequestDTO } from '@myneva-portals/models/src/models/template-request-dto';
import { UsersResponse } from '@myneva-portals/models/src/models/users-response';
import {
  mapFilterParamsToDTO,
  mapSortParamsToDTO,
  mapUserDetailsToAppModel,
  mapUserDetailsToDtoModel,
  mapUsersToAppModel,
} from '@myneva-portals/utils/src/utils/map-dto-data-utils';
import axios from 'axios';
import { SearchParams } from 'front-components/src/components';
import { UsersService } from './users-service';

export class RestUsersService implements UsersService {
  constructor(private url: string) {}

  async getUsers(searchParams: SearchParams): Promise<UsersResponse> {
    const params = {
      ...searchParams,
      filter: mapFilterParamsToDTO(searchParams.filter),
      sort: mapSortParamsToDTO(searchParams.sort),
    };
    const response = await axios.post(this.url + 'admin/search', params, {
      withCredentials: true,
    });
    return mapUsersToAppModel(response.data.result);
  }

  async getUser(id: string): Promise<IUser> {
    const response = await axios.get(this.url + 'admin/' + id, {
      withCredentials: true,
    });
    return mapUserDetailsToAppModel(response.data);
  }

  async addUser(user: IUser): Promise<void> {
    const userModel = mapUserDetailsToDtoModel(user);
    const axiosInstance = await axios.post(this.url + 'admin', userModel, {
      withCredentials: true,
    });
    return axiosInstance.data;
  }

  async updateUser(user: IUser): Promise<void> {
    const userModel = mapUserDetailsToDtoModel(user);
    const axiosInstance = await axios.put(
      this.url + `admin/${userModel.uuid}`,
      userModel,
      {
        withCredentials: true,
      }
    );
    return axiosInstance.data;
  }

  async deleteUser(id: string): Promise<void> {
    await axios.delete(this.url + `admin/${id}`, {
      withCredentials: true,
    });
  }

  async toggleUserStatus(id: string, isEnabled: boolean): Promise<void> {
    await axios.put(
      this.url + `admin/${id}/enabled`,
      { is_enabled: !isEnabled },
      {
        withCredentials: true,
      }
    );
  }

  async addOrEditEmailTemplate(
    emailTemplate: ITemplateRequestDetails
  ): Promise<void> {
    const emailTemplateModel =
      this.mapTemplateRequestDetailsToAppModel(emailTemplate);
    await axios.put(this.url + 'email-template', emailTemplateModel, {
      withCredentials: true,
    });
  }

  async addOrEditSmsTemplate(
    smsTemplate: ITemplateRequestDetails
  ): Promise<void> {
    const emailTemplateModel =
      this.mapTemplateRequestDetailsToAppModel(smsTemplate);
    await axios.put(this.url + 'sms-template', emailTemplateModel, {
      withCredentials: true,
    });
  }

  async getEmailTemplates(): Promise<ITemplateDetails[]> {
    const response = await axios.get(this.url + 'email-template', {
      withCredentials: true,
    });
    return this.mapTemplateDetailsToAppModel(response.data);
  }

  async getSmsTemplates(): Promise<ITemplateDetails[]> {
    const response = await axios.get(this.url + 'sms-template', {
      withCredentials: true,
    });
    return this.mapTemplateDetailsToAppModel(response.data);
  }

  async getAppearanceSettings(): Promise<IAppearanceSettingsTemplate[]> {
    try {
      const response = await axios.get(this.url + 'appearance', {
        withCredentials: true,
      });
      return this.mapAppearanceSettingToAppModel(response.data);
    } catch (error) {
      console.log(error);
      throw new Error('Connection Error');
    }
  }

  async addOrEditAppearanceSettingsTemplate(
    appearanceSettings: IAppearanceSettingsTemplate[]
  ): Promise<void> {
    try {
      const appearanceSettingsTemplateModel =
        this.mapAppearanceSettingsRequestToAppModel(appearanceSettings);
      await axios.put(
        this.url + 'appearance',
        appearanceSettingsTemplateModel,
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.log(error);
      throw new Error('Connection Error');
    }
  }

  async getStandardUsers(searchParams: SearchParams): Promise<UsersResponse> {
    const params = {
      ...searchParams,
      filter: mapFilterParamsToDTO(searchParams.filter),
      sort: mapSortParamsToDTO(searchParams.sort),
    };
    const response = await axios.post(this.url + 'user/search', params, {
      withCredentials: true,
    });
    return mapUsersToAppModel(response.data.result);
  }

  async getStandardUser(id: string): Promise<IUser> {
    const response = await axios.get(this.url + 'user/' + id, {
      withCredentials: true,
    });
    return mapUserDetailsToAppModel(response.data);
  }

  async updateStandardUser(user: IUser): Promise<void> {
    const userModel = mapUserDetailsToDtoModel(user);
    await axios.put(this.url + `user/${userModel.uuid}`, userModel, {
      withCredentials: true,
    });
  }

  async deleteStandardUser(id: string): Promise<void> {
    await axios.delete(this.url + `user/${id}`, {
      withCredentials: true,
    });
  }

  async addStandardUser(user: IUser): Promise<void> {
    const userModel = mapUserDetailsToDtoModel(user);
    const axiosInstance = await axios.post(this.url + 'user', userModel, {
      withCredentials: true,
    });
    return axiosInstance.data;
  }

  async toggleStandardUserStatus(
    id: string,
    isEnabled: boolean
  ): Promise<void> {
    await axios.put(
      this.url + `user/${id}/enabled`,
      { is_enabled: !isEnabled },
      {
        withCredentials: true,
      }
    );
  }

  private mapTemplateDetailsToAppModel(
    templates: ITemplateDetails[]
  ): ITemplateDetails[] {
    if (templates) {
      return templates.map((template: ITemplateDetails) => {
        return {
          id: template.id,
          templateType: {
            id: template.templateType.id,
            description: template.templateType.description,
          },
          tags: template.tags,
          content: template.content,
          subject: template.subject,
          logo: template.logo,
          primary_color: template.primary_color,
          background_color: template.background_color,
        };
      });
    }
    return [];
  }

  private mapAppearanceSettingToAppModel(
    templates: AppearanceSettingsTemplateDTO[]
  ): IAppearanceSettingsTemplate[] {
    if (templates) {
      return templates.map((template: AppearanceSettingsTemplateDTO) => {
        return {
          id: template.id,
          reseller_id: template.reseller_id,
          lightmode: template.lightmode,
          logo: template.logo,
          icon: template.icon,
          primary_color: template.primary_color,
          message_text_bubbles: template.message_text_bubbles,
          secondary_color: template.secondary_color,
          message_text_color: template.message_text_color,
          buttons_and_links: template.buttons_and_links,
        };
      });
    }
    return [];
  }

  private mapTemplateRequestDetailsToAppModel(
    template: ITemplateRequestDetails
  ): TemplateRequestDTO {
    return {
      template_type_id: template.templateTypeId,
      content: template.content,
      subject: template.subject,
      logo: template.logo !== null ? template.logo : '',
      primary_color:
        template.primaryColor !== null ? template.primaryColor : '',
      background_color:
        template.backgroundColor !== null ? template.backgroundColor : '',
    };
  }

  private mapAppearanceSettingsRequestToAppModel(
    templates: IAppearanceSettingsTemplate[]
  ): AppearanceSettingsTemplateDTO[] {
    if (templates) {
      return templates.map((template: IAppearanceSettingsTemplate) => {
        return {
          id: template.id,
          reseller_id: template.reseller_id,
          lightmode: template.lightmode,
          logo: template.logo,
          icon: template.icon,
          primary_color:
            template.primary_color !== null ? template.primary_color : '',
          message_text_bubbles:
            template.message_text_bubbles !== null
              ? template.message_text_bubbles
              : '',
          secondary_color:
            template.secondary_color !== null ? template.secondary_color : '',
          message_text_color:
            template.message_text_color !== null
              ? template.message_text_color
              : '',
          buttons_and_links:
            template.buttons_and_links !== null
              ? template.buttons_and_links
              : '',
        };
      });
    }
    return [];
  }
}
