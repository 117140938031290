import { MyGeneralSettingsDto } from '@myneva-portals/models/src/models/dto/my-general-settings-dto';
import { MyGeneralSettings } from '@myneva-portals/models/src/models/my-organization/my-general-settings';
import {
  mapDtoToMyGeneralSettings,
  mapGeneralSettingstoDto,
} from '@myneva-portals/utils/src/utils/map-dto-data-utils';
import axios from 'axios';
import { SettingsService } from './settings-service';

export class RestSettingsService implements SettingsService {
  constructor(private url: string) {}

  async getMySettings(uuid: string): Promise<MyGeneralSettings> {
    const response = await axios.get(this.url + `${uuid}`, {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
    const responseData: MyGeneralSettingsDto = response.data;
    return mapDtoToMyGeneralSettings(responseData);
  }

  async setMyGeneralSettings(
    generalSettings: MyGeneralSettings,
    uuid: string
  ): Promise<void> {
    const myGeneralSettings = mapGeneralSettingstoDto(generalSettings);
    await axios.put(this.url + `${uuid}`, myGeneralSettings, {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
  }
}
