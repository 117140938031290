import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import UiReducer from './reducers/ui';
import NavigationReducer from './reducers/navigation';
import SessionReducer from './reducers/session';
import ResellersReducer from './reducers/resellers';
import ResellerDetailsReducer from './reducers/reseller-details';
import AdminsReducer from './reducers/admins';
import AdminsDetailsReducer from './reducers/admins-details';
import TemplateDetails from './reducers/email-template-details';

const rootReducer = combineReducers({
  ui: UiReducer,
  navigation: NavigationReducer,
  session: SessionReducer,
  resellers: ResellersReducer,
  resellerDetails: ResellerDetailsReducer,
  admins: AdminsReducer,
  adminsDetails: AdminsDetailsReducer,
  templateDetails: TemplateDetails,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
