import axios from 'axios';
import {
  mapCustomerToAppModel,
  mapCustomerDetailsToAppModel,
  mapCustomerToDtoModel,
  mapOrganizationToAppModel,
  mapLevelToDtoModel,
  mapSortParamsToDTO,
  mapMyCustomerOrganizationToAppModel,
  mapCustomerFilterParamsToDTO,
} from '@myneva-portals/utils/src/utils/map-dto-data-utils';
import { CustomerService } from './customer-service';
import { SearchParams } from 'front-components/src/components';
import { CustomerResponse } from '@myneva-portals/models/src/models/customer/customer-response';

class RestCustomerService implements CustomerService {
  url = process.env.WEB_GATEWAY_URL;

  async getCustomers(
    resellerId: string,
    searchParams: SearchParams
  ): Promise<CustomerResponse> {
    const params = {
      ...searchParams,
      filter: mapCustomerFilterParamsToDTO(searchParams.filter),
      sort: mapSortParamsToDTO(searchParams.sort),
    };
    const response = await axios.post(this.url + '/customer/search', params, {
      withCredentials: true,
      params: {
        resellerId: resellerId,
      },
    });

    return mapCustomerToAppModel(response.data.result);
  }

  async getCustomerDetails(id: string) {
    const response = await axios.get(this.url + '/customer/' + id, {
      withCredentials: true,
    });
    return mapCustomerDetailsToAppModel(response.data);
  }

  async getMyOrganizationDetails() {
    const response = await axios.get(this.url + '/customer/my', {
      withCredentials: true,
    });
    return mapMyCustomerOrganizationToAppModel(response.data);
  }

  async getCustomerId() {
    const response = await axios.get(this.url + '/customer/my', {
      withCredentials: true,
    });
    return response.data.id;
  }

  async addCustomer(customer, userResellerId): Promise<void> {
    const customerModel = mapCustomerToDtoModel(customer, userResellerId);
    const axiosInstance = await axios.post(
      this.url + '/customer',
      customerModel,
      {
        withCredentials: true,
      }
    );
    return axiosInstance.data;
  }

  async toggleCustomerStatus(
    id: number,
    currentStatus: boolean
  ): Promise<void> {
    await axios.put(
      this.url + '/customer/' + id + '/enabled',
      { is_enabled: !currentStatus },
      {
        withCredentials: true,
        headers: { 'Access-Control-Allow-Origin': '*' },
      }
    );
  }

  async updateCustomer(reseller, id: number): Promise<void> {
    const customerModel = mapCustomerToDtoModel(reseller);
    await axios.put(this.url + '/customer/' + id, customerModel, {
      withCredentials: true,
    });
  }
  async deleteCustomer(id: number): Promise<void> {
    await axios.delete(this.url + '/customer/' + id, {
      withCredentials: true,
    });
  }

  async getOrganzationStructure(customerId: string): Promise<void> {
    const response = await axios.get(
      this.url + '/customer/' + customerId + '/structure',
      {
        withCredentials: true,
        headers: { 'Access-Control-Allow-Origin': '*' },
      }
    );
    await mapOrganizationToAppModel(response.data);
    return response.data;
  }

  async deleteOrganizationLevel(id: number, levelId: number): Promise<void> {
    await axios.delete(this.url + '/customer/' + id + '/structure/' + levelId, {
      withCredentials: true,
    });
  }

  async addLevel(id: number, level: any): Promise<void> {
    const levelModel = mapLevelToDtoModel(level);
    await axios.post(this.url + '/customer/' + id + '/structure', levelModel, {
      withCredentials: true,
    });
  }

  async addSubLevel(id: number, levelId: number, level: any): Promise<void> {
    const levelModel = mapLevelToDtoModel(level);
    await axios.post(
      this.url + '/customer/' + id + '/structure/' + levelId + '/children',
      levelModel,
      {
        withCredentials: true,
      }
    );
  }

  async getOrganizationDetails(id: number, levelId: number) {
    const response = await axios.get(
      this.url + '/customer/' + id + '/structure/' + levelId,
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  async editLevel(id: number, levelId: number, level: any): Promise<void> {
    const levelModel = mapLevelToDtoModel(level);
    await axios.put(
      this.url + '/customer/' + id + '/structure/' + levelId,
      levelModel,
      {
        withCredentials: true,
      }
    );
  }
}

export const restCustomerService: CustomerService = new RestCustomerService();
