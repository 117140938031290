import { MenuSection } from '@myneva-portals/models/src/models/navigation/menu-section';
import PageKey from '@myneva-portals/models/src/models/page-key';

export const userSettingsMenuStructure: MenuSection[] = [
  {
    items: [
      {
        key: PageKey.USER_SETTINGS_OVERVIEW,
        name: 'user-settings',
        level: 1,
      },
      {
        key: PageKey.USER_SETTINGS_GENERAL,
        name: 'user-general-settings',
        level: 2,
      },
    ],
  },
];
