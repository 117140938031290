import axios from 'axios';
import Reseller from '@myneva-portals/models/src/models/reseller/reseller';
import {
  mapResellersToAppModel,
  mapResellersToDetailsAppModel,
  mapResellersToDtoModel,
  mapAddResellerToDtoModel,
  mapMyOrganizationToAppModel,
} from '@myneva-portals/utils/src/utils/map-dto-data-utils';
import { ResellerService } from './reseller-service';
import ResellerDetails from '@myneva-portals/models/src/models/reseller/reseller-details';

class RestResellerService implements ResellerService {
  url = process.env.WEB_GATEWAY_URL;

  async getResellers(): Promise<Reseller[]> {
    const response = await axios.get(this.url + '/reseller', {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
    return mapResellersToAppModel(response.data);
  }

  async getMyOrganizationDetails() {
    const response = await axios.get(this.url + '/reseller/my', {
      withCredentials: true,
    });
    return mapMyOrganizationToAppModel(response.data);
  }

  async getResellerDetails(id: number): Promise<ResellerDetails> {
    const response = await axios.get(this.url + '/reseller/' + id, {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
    return mapResellersToDetailsAppModel(response.data);
  }

  async addReseller(reseller: ResellerDetails): Promise<void> {
    const resellerModel = mapAddResellerToDtoModel(reseller);
    const axiosInstance = await axios.post(
      this.url + '/reseller',
      resellerModel,
      {
        withCredentials: true,
        headers: { 'Access-Control-Allow-Origin': '*' },
      }
    );
    return axiosInstance.data;
  }

  async updateReseller(reseller: ResellerDetails, id: number): Promise<void> {
    const resellerModel = mapResellersToDtoModel(reseller);
    const axiosInstance = await axios.put(
      this.url + '/reseller/' + id,
      resellerModel,
      {
        withCredentials: true,
        headers: { 'Access-Control-Allow-Origin': '*' },
      }
    );
    return axiosInstance.data;
  }

  async deleteReseller(id: number): Promise<void> {
    await axios.delete(this.url + '/reseller/' + id, {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
  }

  async toggleResellerStatus(
    id: string,
    currentStatus: boolean
  ): Promise<void> {
    await axios.put(
      this.url + '/reseller/' + id + '/enabled',
      { is_enabled: !currentStatus },
      {
        withCredentials: true,
        headers: { 'Access-Control-Allow-Origin': '*' },
      }
    );
  }

  async getCoreResellers(): Promise<Reseller[]> {
    const response = await axios.get(this.url + '/core/reseller', {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
    return mapResellersToAppModel(response.data);
  }
}

export const restResellerService: ResellerService = new RestResellerService();
